import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allLoading
    ? _c(
        VContainer,
        [
          _vm._v(" " + _vm._s(_vm.vehicleStocks) + " "),
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        VCardText,
        [
          _c(
            VSimpleTable,
            { staticClass: "mt-14 mb-6 table-stocks" },
            [
              _c(
                VBtn,
                {
                  staticClass: "button-add-stock",
                  attrs: {
                    "data-test": "Vehicle:AddVehicleStocks:BtnAddStock",
                    color: "primary",
                    disabled: !_vm.vehicleId,
                    rounded: "",
                    fab: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openStockDialog(null)
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              _c(
                VBtn,
                {
                  staticClass: "button-file-stock",
                  attrs: {
                    disabled: !_vm.vehicleId,
                    color: "secondary",
                    rounded: "",
                    fab: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openDialogFile()
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { large: "" } }, [
                    _vm._v("mdi-file-upload"),
                  ]),
                ],
                1
              ),
              _c("thead", [
                _c("tr", [
                  _c("th", { staticClass: "text-center" }, [_vm._v("Id")]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("name"))),
                  ]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("description"))),
                  ]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$tc("stock", 1))),
                  ]),
                  _c("th", { staticClass: "text-center" }),
                  _c("th", { staticClass: "text-center" }),
                ]),
              ]),
              _vm.vehicleStocks.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.vehicleStocks, function (stock, i) {
                      return _c("tr", { key: i }, [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(stock.stockTypeId)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(stock.stockTypeName)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(stock.stockTypeDescription)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatCurrency(
                                  stock.currency,
                                  stock.value,
                                  2,
                                  8
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 secondary",
                                attrs: { "min-width": "0", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAddNewValueStock(stock)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-plus"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 secondary",
                                attrs: {
                                  "data-test":
                                    "Vehicle:AddVehicleStocks:BtnEditStock",
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openStockDialog(stock)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 secondary",
                                attrs: { "min-width": "0", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      "/vehicle/" +
                                        _vm.$route.params.vehicleId +
                                        "/stockType/" +
                                        stock.stockTypeId +
                                        "/history"
                                    )
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-eye"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1",
                                attrs: {
                                  color: "red",
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDeleteDialog(stock)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-trash-can"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.vehicleId
            ? _c(VAlert, { attrs: { type: "info" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("alert_required_vehicle_to_stock")) + " "
                ),
              ])
            : _vm._e(),
          _vm.stockDialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.stockDialog,
                    callback: function ($$v) {
                      _vm.stockDialog = $$v
                    },
                    expression: "stockDialog",
                  },
                },
                [
                  _c("AddStockModal", {
                    attrs: {
                      addNewValueStock: _vm.addNewValueStock,
                      stockEdit: _vm.stockEdit,
                      vehicleInfo: _vm.vehicleInfo,
                      vehicleIsInternational: _vm.VehicleIsInternational,
                      vehicleRequestLoading: _vm.VehicleRequestLoading,
                      vehicleRequestSuccess: _vm.VehicleRequestSuccess,
                    },
                    on: {
                      close: _vm.closeStockModal,
                      reloadStocks: _vm.reloadStocks,
                      reloadVehicleData: function () {
                        return _vm.emitReloadVehicleData()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.deleteDialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.deleteDialog,
                    callback: function ($$v) {
                      _vm.deleteDialog = $$v
                    },
                    expression: "deleteDialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: {
                      obj: _vm.deleteObj,
                      name: _vm.deleteObj.stockTypeName,
                      alert: _vm.deleteDialogAlert,
                    },
                    on: {
                      close: function ($event) {
                        _vm.deleteDialog = false
                      },
                      delete: _vm.deleteStock,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.openDialogStockFile
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.openDialogStockFile,
                    callback: function ($$v) {
                      _vm.openDialogStockFile = $$v
                    },
                    expression: "openDialogStockFile",
                  },
                },
                [
                  _c("DialogAddStockFile", {
                    attrs: { vehicleId: _vm.vehicleId },
                    on: {
                      success: _vm.handleSuccessStockFile,
                      close: function ($event) {
                        _vm.openDialogStockFile = false
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }