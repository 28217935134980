import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "564" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-publish-round-pipeline-error-bg",
              attrs: { "data-test": "Vehicle:ErrorHandler" },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-publish-round-pipeline-error-content" },
                [
                  _c("font-awesome-icon", {
                    staticClass:
                      "dialog-publish-round-pipeline-alert-icon-error",
                    attrs: { icon: "fa-solid fa-circle-xmark" },
                  }),
                  _c(
                    "h1",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-error-content-title",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("error")) + " ")]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-error-content-text dialog-publish-round-pipeline-error-content-list",
                    },
                    _vm._l(_vm.errorData, function (errorList, key) {
                      return _c(
                        "ul",
                        { key: key, staticClass: "error-category-container" },
                        [
                          _c("span", { staticClass: "error-category" }, [
                            _vm._v(_vm._s(_vm.$t(key))),
                          ]),
                          _vm._l(errorList, function (error, z) {
                            return _c("li", { key: z }, [
                              _vm._v(" " + _vm._s(_vm.$t(error)) + " "),
                            ])
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-publish-round-pipeline-error-button",
                    },
                    [
                      _c("NewDxaButton", {
                        attrs: { outline: true, title: _vm.$t("close") },
                        on: {
                          btnFunction: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("font-awesome-icon", {
                staticClass:
                  "dialog-publish-round-pipeline-error-icon-close-dialog",
                attrs: {
                  icon: "fa-solid fa-xmark",
                  "data-test": "ErrorHandler:Close",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }