import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allLoading
    ? _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        VContainer,
        { attrs: { id: "category-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                icon: "mdi-currency-usd",
                inline: "",
                color: "secondary",
              },
              scopedSlots: _vm._u([
                {
                  key: "after-heading",
                  fn: function () {
                    return [
                      _c("h1", { staticClass: "h5" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("add_new_vehicle")))]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                VTabs,
                {
                  attrs: { grow: "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    VTab,
                    {
                      attrs: {
                        "data-test": "Vehicle:AddVehicle:BtnToDataVehicle",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vehicle_data")))]
                  ),
                  _c(
                    VTab,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vehicle.closingType ===
                            _vm.vehicleClosingOptionsEnum.CLOSING_SA,
                          expression:
                            "vehicle.closingType === vehicleClosingOptionsEnum.CLOSING_SA",
                        },
                      ],
                      attrs: { disabled: !_vm.nextStep },
                    },
                    [_vm._v(_vm._s(_vm.$t("documents_title")))]
                  ),
                  _c(
                    VTab,
                    {
                      attrs: {
                        disabled: !_vm.nextStep,
                        "data-test": "Vehicle:AddVehicle:BtnToStock",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("stocks")))]
                  ),
                ],
                1
              ),
              _vm.tab === _vm.vehicleTabEnum.vehicleData
                ? _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          ref: "form",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.checkForm()
                            },
                          },
                        },
                        [
                          _c(
                            VRadioGroup,
                            {
                              staticClass: "mt-5",
                              attrs: { row: "", "hide-details": "" },
                              on: { change: _vm.handleSelectNationality },
                              model: {
                                value: _vm.vehicle.vehicleNationality,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vehicle,
                                    "vehicleNationality",
                                    $$v
                                  )
                                },
                                expression: "vehicle.vehicleNationality",
                              },
                            },
                            [
                              _c(VRadio, {
                                staticClass: "mr-10",
                                attrs: {
                                  color: "primary",
                                  label: "Veículo Nacional",
                                  value: _vm.vehicleNationalityOptions.NATIONAL,
                                  "data-test":
                                    "Vehicle:AddVehicle:Nationality:National",
                                },
                              }),
                              _c(VRadio, {
                                attrs: {
                                  color: "primary",
                                  label: "Veículo Internacional",
                                  value:
                                    _vm.vehicleNationalityOptions.INTERNATIONAL,
                                  "data-test":
                                    "Vehicle:AddVehicle:Nationality:International",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "close-options-select-container" },
                            [
                              _c(
                                "span",
                                { staticClass: "closing-options-select-label" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("closing_config")) + " "
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "closing-type-icon-info",
                                                      attrs: {
                                                        icon: "fa-solid fa-circle-info",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3884690048
                                      ),
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "closing-type-info-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "closing_type_tolltip_text"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(VSelect, {
                                staticClass: "closing-options-select",
                                attrs: {
                                  items: _vm.closingOptions,
                                  "item-text": "text",
                                  "item-value": "value",
                                  outlined: "",
                                  label: _vm.$t("select_option"),
                                  rules: [_vm.required],
                                  "data-test":
                                    "Vehicle:AddVehicle:ClosingOptions",
                                },
                                model: {
                                  value: _vm.vehicle.closingType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vehicle, "closingType", $$v)
                                  },
                                  expression: "vehicle.closingType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-add-vehicle-container" },
                            [
                              _c(
                                "span",
                                { staticClass: "form-add-vehicle-title" },
                                [_vm._v(_vm._s(_vm.$t("vehicle_banker_data")))]
                              ),
                              _c(VTextField, {
                                attrs: {
                                  "data-test": "Vehicle:AddVehicle:FirstName",
                                  label: _vm.$t("name"),
                                  rules: [_vm.required],
                                },
                                model: {
                                  value: _vm.vehicle.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vehicle, "name", $$v)
                                  },
                                  expression: "vehicle.name",
                                },
                              }),
                              !_vm.vehicleIsInternational
                                ? _c(VTextField, {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##.###.###/####-##"],
                                        expression: "['##.###.###/####-##']",
                                      },
                                    ],
                                    attrs: {
                                      "data-test": "Vehicle:AddVehicle:Cnpj",
                                      placeholder: "00.000.000/0000-00",
                                      rules: [
                                        function (v) {
                                          return (
                                            (!!v && v.length === 18) ||
                                            _vm.required()
                                          )
                                        },
                                      ],
                                      label: _vm.$t("company_id"),
                                    },
                                    model: {
                                      value: _vm.vehicle.cnpj,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.vehicle, "cnpj", $$v)
                                      },
                                      expression: "vehicle.cnpj",
                                    },
                                  })
                                : _vm._e(),
                              !_vm.vehicleIsInternational
                                ? _c(VTextField, {
                                    attrs: {
                                      "data-test": "Vehicle:AddVehicle:Nire",
                                      label: _vm.$t("nire"),
                                      rules: [_vm.required],
                                    },
                                    model: {
                                      value: _vm.vehicle.nire,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.vehicle, "nire", $$v)
                                      },
                                      expression: "vehicle.nire",
                                    },
                                  })
                                : _vm._e(),
                              !_vm.vehicleIsInternational
                                ? _c(
                                    "div",
                                    [
                                      _c(VAutocomplete, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("insert_bank_code"),
                                          color: "primary",
                                          "no-data-text": _vm.$t("not_found"),
                                          items: _vm.banks,
                                          filter: _vm.customFilter,
                                          "item-value": "COMPE",
                                          "data-test":
                                            "Vehicle:AddVehicle:Bank",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.COMPE +
                                                          " - " +
                                                          data.item.LongName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.COMPE +
                                                          " - " +
                                                          data.item.LongName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          975023836
                                        ),
                                        model: {
                                          value: _vm.bankData.bank,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bankData, "bank", $$v)
                                          },
                                          expression: "bankData.bank",
                                        },
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          "data-test":
                                            "Vehicle:AddVehicle:BankAgency",
                                          placeholder:
                                            _vm.$t("insert_bank_agency"),
                                        },
                                        model: {
                                          value: _vm.bankData.agency,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankData,
                                              "agency",
                                              $$v
                                            )
                                          },
                                          expression: "bankData.agency",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "insert_bank_account"
                                          ),
                                          "data-test":
                                            "Vehicle:AddVehicle:BankAccount",
                                        },
                                        model: {
                                          value: _vm.bankData.account,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bankData,
                                              "account",
                                              $$v
                                            )
                                          },
                                          expression: "bankData.account",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("insert_bank_pix"),
                                          "data-test":
                                            "Vehicle:AddVehicle:BankPix",
                                        },
                                        model: {
                                          value: _vm.bankData.pix,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bankData, "pix", $$v)
                                          },
                                          expression: "bankData.pix",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("insert_bank_name"),
                                          "data-test":
                                            "Vehicle:AddVehicle:Bank",
                                        },
                                        model: {
                                          value: _vm.interBankAccounts.bank,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "bank",
                                              $$v
                                            )
                                          },
                                          expression: "interBankAccounts.bank",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "insert_bank_account"
                                          ),
                                          "data-test":
                                            "Vehicle:AddVehicle:Account",
                                        },
                                        model: {
                                          value: _vm.interBankAccounts.account,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "account",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "interBankAccounts.account",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: "Beneficiário",
                                          "data-test":
                                            "Vehicle:AddVehicle:Recipient",
                                        },
                                        model: {
                                          value:
                                            _vm.interBankAccounts.recipient,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "recipient",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "interBankAccounts.recipient",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: _vm.$t("address"),
                                          "data-test":
                                            "Vehicle:AddVehicle:Address",
                                        },
                                        model: {
                                          value: _vm.interBankAccounts.address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "interBankAccounts.address",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: _vm.$t("swift"),
                                          "data-test":
                                            "Vehicle:AddVehicle:Swift",
                                        },
                                        model: {
                                          value: _vm.interBankAccounts.swift,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "swift",
                                              $$v
                                            )
                                          },
                                          expression: "interBankAccounts.swift",
                                        },
                                      }),
                                      _c(VTextField, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder: _vm.$t("aba"),
                                          "data-test": "Vehicle:AddVehicle:Aba",
                                        },
                                        model: {
                                          value:
                                            _vm.interBankAccounts.routingNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "routingNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "interBankAccounts.routingNumber",
                                        },
                                      }),
                                      _c(VTextarea, {
                                        staticClass: "field",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("other_informations"),
                                          "data-test":
                                            "Vehicle:AddVehicle:OtherInfo",
                                        },
                                        model: {
                                          value:
                                            _vm.interBankAccounts
                                              .otherInformation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.interBankAccounts,
                                              "otherInformation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "interBankAccounts.otherInformation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                              _c(
                                VCardActions,
                                { staticClass: "pl-0 dxa_modal_actions" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "dxa_modal_btnSuccess",
                                      attrs: {
                                        "data-test":
                                          "Vehicle:AddVehicle:BtnSaveVehicle",
                                        color: "primary",
                                        "min-width": "100",
                                        loading: _vm.loading,
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("save")))]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "dxa_modal_btnError",
                                      attrs: {
                                        color: "secondary",
                                        "min-width": "100",
                                      },
                                      on: { click: _vm.goBack },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("go_back")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === _vm.vehicleTabEnum.vehicleDocuments
                ? _c("VehicleDocuments", {
                    attrs: {
                      VehicleInfo: _vm.vehicle,
                      VehicleRequestSuccess: _vm.vehicleRequestSuccess,
                      VehicleRequestLoading: _vm.vehicleRequestLoading,
                    },
                    on: {
                      addVehicleDocuments: function (e) {
                        return _vm.addVehicleDocuments(e)
                      },
                      removeVehicleDocuments: function (e) {
                        return _vm.removeVehicleDocuments(e)
                      },
                    },
                  })
                : _vm._e(),
              _vm.tab === _vm.vehicleTabEnum.vehicleStocks
                ? _c("VehicleStocks", {
                    attrs: {
                      VehicleIsInternational: _vm.vehicleIsInternational,
                      VehicleInfo: _vm.vehicle,
                      BankAccountsProp: _vm.bankAccountsProp,
                      VehicleRequestSuccess: _vm.vehicleRequestSuccess,
                      VehicleRequestLoading: _vm.vehicleRequestLoading,
                    },
                    on: {
                      submitVehicle: function (e) {
                        return _vm.submit(e)
                      },
                      reloadVehicleData: function () {
                        return _vm.getVehicleInfo()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("ErrorHandler", {
            attrs: {
              visible: _vm.errorHandlerVisible,
              errorData: _vm.errorData,
            },
            on: {
              close: function ($event) {
                _vm.errorHandlerVisible = false
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }