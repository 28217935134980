import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorData
    ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
        _c(
          "div",
          _vm._l(_vm.errorData, function (error, i) {
            return _c("div", { key: i }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("line") + ": " + ++error.erroLine)),
              ]),
              _c(
                "ul",
                _vm._l(error.errosAlertKeys, function (erroKey, i) {
                  return _c("li", { key: i }, [
                    error.erroMainKey
                      ? _c("span", { staticClass: "text-base" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(erroKey) +
                                " ( " +
                                _vm.$t(error.erroMainKey) +
                                " )"
                            )
                          ),
                        ])
                      : _c("span", { staticClass: "text-base" }, [
                          _vm._v(_vm._s(_vm.$t(erroKey))),
                        ]),
                  ])
                }),
                0
              ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }